import React from 'react';
import PropTypes from 'prop-types';
import './layout.css';

const Layout = ({ children }) => {
	return (
		<div className="dark:bg-darkBlue bg-lightDust">
			{children}
			<footer
				style={{
					marginTop: `2rem`,
				}}
			>
				Contact information
			</footer>
		</div>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
